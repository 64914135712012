@use 'src/styles/global' as *;

#tablet-and-desktop-thirdparty-layout-wrapper.main-app-wrapper {
  #webrms-sidebar {
    z-index: 9999;
    @include is-venue-access-blocked {
      z-index: var(--blocked-access-z-index);
    }
  }

  @include is-venue-in-warning {
    padding-top: var(--payment-warning-height);
  }

  #main-content {
    position: relative;
    margin-left: 0;
    height: 100%;

    &.has-side-bar {
      margin-left: $sidebar-width;
    }

    .body {
      padding-top: 0px;
      height: 100%;
      &:not(.has-no-footer) {
        height: calc(100% - #{$tablet-footer-height});
      }
    }

    #tablet-footer {
      position: fixed;
      bottom: 0;
      left: $sidebar-width;
      right: 0;
    }
  }
}
