@use 'src/styles/global' as *;

#tablet-and-desktop-layout-wrapper.main-app-wrapper {
  @include is-venue-in-warning {
    padding-top: var(--payment-warning-height);

    #main-content {
      > header {
        top: var(--payment-warning-height);
      }
    }
  }

  #main-content {
    position: relative;
    margin-left: $sidebar-width;
    padding-top: $tablet-header-height;
    height: 100%;
    overflow: hidden;
    &.has-quick-filter {
      padding-top: $tablet-height-with-quick-filter;
    }

    > header {
      position: fixed;
      top: 0;
      left: $sidebar-width;
      right: 0;
      z-index: 2;
      min-height: 52px;
      box-shadow: $header-box-shadow;
    }

    .body {
      height: calc(100% - #{$tablet-footer-height});
      overflow: auto;
      margin: 0 0.5rem;
      scroll-behavior: smooth;

      &.is-white {
        background: $white;
        min-height: $vh100;
      }
    }

    #tablet-footer {
      position: fixed;
      bottom: 0;
      left: $sidebar-width;
      right: 0;
    }
  }
}
