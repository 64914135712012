@use 'src/styles/global' as *;

:root {
  --payment-warning-height: 40px;
  --blocked-access-z-index: 10001;
}

.blockedAccessModal {
  > div {
    cursor: not-allowed;
  }

  > div > div {
    @include mobileAndLandscape {
      top: 40% !important;
      max-height: calc(95vh - #{$mobile-funnel-footer-height--small}) !important;
    }
  }
}

.blockedAccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 32px 64px;
  width: 650px;
  @include mobile() {
    padding: 24px 32px;
    width: 100%;
  }

  svg {
    height: 65px;
    width: 234px;
    @include tabletAndAbove() {
      height: 85px;
    }
    path,
    rect {
      fill: $green-primary;
    }
  }

  .title {
    color: $danger-button;
    font-size: $font-size-xl;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }

  .description {
    color: $text-color-primary;
    font-size: $font-size-lg;
    line-height: 19px;
    padding: 0 32px;
    @include mobile {
      padding: 0 16px;
    }
    max-height: 250px;
    overflow: auto;
  }

  .goToBillingButton {
    @include button-reset;
    background-color: $green-primary;
    font-weight: 400;
    font-size: $font-size-lg;
    line-height: 19px;
    text-align: center;
    color: $white;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: $border-radius-xl;
    width: 340px;
    height: 60px;
    @include mobile {
      width: 280px;
    }

    &:focus {
      outline: 1px solid $focus-outline;
    }

    &:hover {
      background-color: darken($color: $green-primary, $amount: 5);
    }

    &:disabled {
      background-color: $grey;
      cursor: default;
      outline: none;
    }
  }
}
