@use 'src/styles/global' as *;

.reservation-charge-icon {
  color: $paid;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .charge-icon-and-text {
    &.payment-charged {
      color: $attention-warning;
    }

    &.payment-pending {
      color: $yellow-orange;
    }

    svg {
      vertical-align: middle;
    }
  }

  @include mobile() {
    padding-bottom: 0.25rem;
  }

  &.is-selected,
  &.is-attention {
    color: $white;
  }

  svg,
  img {
    margin-right: 0.2rem;
  }
}
