@use 'src/styles/global' as *;

.guest-loyalty-summary-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.25rem;

    > :first-child {
      color: $text-color-secondary;
      font-size: $font-size-md;
    }

    > :last-child {
      color: $text-color-primary;
      font-weight: 400;
      font-size: $font-size-lg;
    }
  }

  &.skeleton {
    > * {
      flex-direction: row;
    }
  }
}
