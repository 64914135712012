@use 'src/styles/global' as *;

.referralBanner {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.popupContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 2.5rem 1rem 1.5rem 1rem;
  max-width: 696px;

  @include tabletAndAbove() {
    padding: 2.5rem 6rem 1.5rem 6rem;
  }

  .title {
    font-weight: 700;
    font-size: $font-size-xl;
    line-height: 22px;
    color: $black;
    text-align: center;
  }

  .popupImage {
    border-radius: $border-radius-md;
    width: 300px;
    height: 160px;
  }

  .reward {
    font-size: $font-size-xxxl;
    font-weight: 700;
    line-height: 29px;
    color: $black;
    text-align: center;
  }

  .text {
    font-size: $font-size-md;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    color: $text-color-secondary;
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 340px;
    margin-bottom: 0.5rem;
  }

  .referralBtn {
    width: 100%;
    min-width: 300px;
    padding: 0 1rem;
    border: none;
    height: 60px;

    @include tabletAndAbove() {
      min-width: 340px;
      padding: 0 pxToRem(45);
    }

    &.noThanksBtn {
      &:hover {
        color: $green-primary;
      }
    }
  }
}
