@use 'src/styles/global' as *;

:root {
  --timeline-font-color: #{$white};
  --timeline-x-axis-color: #{$footer-button};
  --timeline-y-axis-color: #1c1a2e;
  --timeline-center-grid-open-day-color: #{$gray-header};
  --timeline-center-grid-closed-day-color: #383f4a;
  --timeline-current-time-color: #{$light-orange};
  --timeline-border-color: #767c86;
  // NOTE: these height should be synced with the const defined in timeline constants:
  // ROOM_HEIGHT = 30; TABLE_HEIGHT = 57;
  --timeline-height-room: 30px;
  --timeline-height-table: 57px;
  --timeline-y-axis-width: 75px;
  --timeline-item-height-diff: 5px;
  --timeline-width-status-button: 80px;
  --timeline-accordion-size: 12px;

  --timeline-y-axis-display: inline-block;
  --timeline-y-axis-table-width: 100%;
  --timeline-y-axis-pax-width: 100%;
  --timeline-overlap-count-size: 20px;
  --timeline-item-icon-width: 27px;
  --timeline-item-name-font-size: #{$font-size-lg};
  --timeline-item-time-font-size: #{$font-size-md}; // 14px
  --timeline-item-charge-font-size: #{$font-size-md}; // 14px
  --timeline-group-table-font-size: #{$font-size-md}; // 14px
  --timeline-group-summary-font-size: #{$font-size-md}; // 14px
}

// using specific selector css to make sure css are overriding with vis-timeline in node_modules.
// For more details: https://developer.mozilla.org/en-US/docs/Web/CSS/Specificity
div.timeline {
  background-color: var(--timeline-x-axis-color);
  user-select: none;

  .vis-timeline {
    // needed to unset transform here because transforming translateX makes position fixed to not work as expected,
    // it happens because transform creates a new coordinate system and your position: fixed element becomes relative to that transformed element.
    // P.S: in our case .vis-top would scroll on list-open
    transform: unset !important;
    transition: none !important;
    user-select: none;
  }

  .vis-panel,
  .vis-timeline {
    border: none;
    scroll-behavior: smooth;
  }

  .vis-panel.vis-top {
    background-color: var(--timeline-x-axis-color);
    position: fixed;
    border-bottom: 1px solid var(--timeline-border-color);
    @include tabletAndAbove {
      left: calc(var(--timeline-y-axis-width) + $sidebar-width) !important;
    }

    .vis-major {
      height: 9px;
    }

    .vis-minor {
      left: 0;
      right: 0;
      bottom: 15px;
      &::before {
        content: '\2014';
        display: inline-block;
        transform: rotate(90deg);
        width: 0;
        color: white;
        top: 15px;
        position: absolute;
      }
    }

    .vis-text {
      font-size: $font-size-md;
      padding: 0;
      color: var(--timeline-font-color);
      overflow: visible;
    }
  }

  @include is-venue-in-warning {
    .vis-panel.vis-top {
      top: var(--payment-warning-height) !important;
    }
  }

  &.mobile {
    .vis-panel.vis-top {
      @include mobileAndLandscape {
        left: var(--timeline-y-axis-width) !important;
      }
    }
  }

  .vis-panel.vis-left {
    background-color: var(--timeline-y-axis-color);
    width: var(--timeline-y-axis-width);
    margin-left: 1px;
    z-index: 1;

    .vis-label {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: none;
      background-color: var(--timeline-y-axis-color);
      transition: height 200ms ease-in-out;

      &.vis-nesting-group {
        &.collapsed:before,
        &.expanded:before {
          content: '';
          width: 0;
        }

        &.collapsed:after {
          content: '\25B8';
          font-size: var(--timeline-accordion-size);
        }

        &.expanded:after {
          content: '\25BE';
          font-size: var(--timeline-accordion-size);
        }
      }
    }

    .room {
      background-color: var(--timeline-x-axis-color);
      height: var(--timeline-height-room) !important;
      color: var(--timeline-font-color);
      cursor: pointer;

      .vis-inner {
        // --timeline-accordion-size is for the arrow used in accordion of the rooms
        width: calc(var(--timeline-y-axis-width) - var(--timeline-accordion-size));
        padding: 0;
      }

      &.no-table-room {
        background-color: $danger-button;
      }
    }

    .table {
      height: var(--timeline-height-table) !important;
      color: var(--timeline-font-color);
      padding: 0;

      .vis-inner {
        width: var(--timeline-y-axis-width);
        padding: 0 5px;
        display: var(--timeline-y-axis-display);
      }

      &.no-table {
        background-color: $danger-button;
      }
    }
  }

  .vis-panel.vis-center {
    .vis-foreground {
      .table,
      .room {
        border-bottom: 1px solid var(--timeline-border-color);
      }
    }

    .vis-item {
      border-radius: $border-radius-md;
      border: 0;
    }

    .vis-item-overflow {
      overflow: visible;
    }

    .vis-selected {
      background: none;
    }

    .vis-item-content {
      display: inline;
      padding: 0;
    }

    .vis-onUpdateTime-tooltip {
      width: auto;
      background-color: $color-primary;
      border-top-left-radius: $border-radius-md;
      border-top-right-radius: $border-radius-md;
      opacity: 0.7;
      color: $white;
      font-size: $font-size-lg;
      font-weight: 600;
      padding: 5px;
    }

    .vis-drag-center,
    .vis-drag-left {
      display: none;
    }

    .vis-drag-right {
      right: 4px;
    }

    .room {
      background-color: var(--timeline-x-axis-color);
      height: var(--timeline-height-room) !important;
      transition: height 200ms ease-in-out;
    }

    .table {
      height: var(--timeline-height-table) !important;
      transition: height 200ms ease-in-out;
    }

    .open-day {
      background-color: var(--timeline-center-grid-open-day-color);
      border-left: 1px solid var(--timeline-border-color);
      border-radius: 0;
      z-index: -1;
    }
  }

  &.is-today {
    .vis-center {
      .table {
        cursor: pointer;
        &.no-table {
          cursor: default;
        }
      }
    }
  }

  .vis-panel.vis-vertical {
    .vis-time-axis .vis-grid {
      // By default the timeline grid color is closed-day color
      background-color: var(--timeline-center-grid-closed-day-color);
      border-left: 1px solid var(--timeline-border-color);
    }

    .vis-current-time {
      background-color: var(--timeline-current-time-color);
      position: fixed !important;

      &::before {
        content: attr(data-time);
        background-color: var(--timeline-current-time-color);
        color: $black;
        position: absolute;
        border-radius: 4px;
        padding: 2px 5px;
        right: -40px;
        text-align: center;
        width: 75px;
        font-size: $font-size-md;
        @include largeDesktop {
          width: 80px;
        }
      }
    }
  }
}

.freemium-timeline-portal {
  .ReactModal__Overlay {
    z-index: 1 !important;
  }
}

.freemium-timeline {
  width: 100%;
  height: 100%;
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../assets/images/timeline-freemium.png') no-repeat top left;

  @include mobileAndLandscape {
    background:
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../assets/images/timeline-freemium.png') no-repeat top center;
  }

  @include mobile {
    background:
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../assets/images/timeline-freemium.png') no-repeat top center;
  }

  & {
    background-size: cover;
  }
}
