@use 'src/styles/global' as *;

.content {
  overflow-y: auto;
  max-height: 50vh;
  padding: pxToRem(8) pxToRem(16);
  font-size: $font-size-md;

  svg {
    margin-right: pxToRem(4);
    vertical-align: middle;
  }

  .heading {
    font-size: $font-size-md;
    font-weight: 700;
  }

  .name {
    display: flex;
    gap: pxToRem(8);
    align-items: center;
    margin-bottom: pxToRem(4);

    .phone {
      display: inline;
      color: $text-color-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .guest {
    display: flex;
    flex-direction: column;
    gap: pxToRem(4);

    .summary {
      > div > div {
        padding: 0;
        align-items: flex-start;

        :first-child {
          font-size: $font-size-md;
          cursor: text;
        }
      }
    }
  }

  .lastReservation,
  .upcomingReservation {
    display: flex;
    flex-direction: column;
    gap: pxToRem(4);
    margin-top: pxToRem(16);

    .status {
      font-size: $font-size-md;
      font-weight: 400;
      line-height: pxToRem(16.94);
      text-align: left;
      margin-left: pxToRem(8);
      svg {
        vertical-align: middle;
        margin-top: -3px;
      }
    }

    .payment {
      justify-content: flex-start;
    }

    .supplements {
      > div {
        border: 0;
        border-radius: 0;
        margin: 0;

        > div {
          padding: 0;
        }
      }
    }
  }

  .details {
    display: inline;

    > * {
      margin-right: pxToRem(8);
    }
  }

  .note {
    display: inline;
    text-align: left;
  }
}
