@use 'src/styles/global/variables' as *;
@use 'src/styles/global/colors' as *;

#tablet-footer {
  height: $tablet-footer-height;

  li > a,
  li > button {
    flex-direction: row;
  }

  .left-content {
    justify-content: flex-start;
    align-items: center;

    li {
      flex: none;
      padding: 0.65rem;
      justify-content: flex-start;

      a,
      button {
        height: 44px;

        &:focus-visible {
          outline: 1px solid $focus-outline;
        }

        span {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg {
          margin-right: 0.3rem;
        }
      }

      &.availabilities-selector {
        margin-left: 1rem;
      }
    }
  }

  .right-content {
    align-items: center;

    li {
      flex: none;

      a,
      button {
        height: 44px;
        &:focus-visible {
          outline: 1px solid $focus-outline;
        }
      }

      &:last-of-type {
        justify-content: flex-end;
        margin-left: auto;
        column-gap: 0.5rem;
      }
    }
  }
}
