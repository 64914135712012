@use 'src/styles/global' as *;

.panel-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .panel-combination,
  .panel {
    width: 100%;
    @include tabletAndAbove() {
      width: calc(50% - 6px);
      margin-left: 3px;
      margin-right: 3px;
      margin-bottom: auto;
      justify-content: center;
      align-items: center;

      &:nth-child(odd) {
        margin-left: 0;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  .panel-combination .panel {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.panel {
  border: $panel-border;
  border-radius: $border-radius-md;
  margin-top: 0.75rem;

  &.is-clickable {
    cursor: pointer;
  }

  .panel-header {
    display: flex;
    border-bottom: $panel-border;
    align-items: center;
    min-height: 30px !important;
    font-size: $font-size-sm;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;

    button {
      @include button-reset;
      max-width: 100px;
      color: $green-primary;
    }

    > :last-child:not(:first-child) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .panel-body {
    padding: 1rem;
    overflow: auto;
    max-height: 50vh;
    font-size: $font-size-md;

    .panel-body-section-title {
      border-bottom: $panel-border;
      padding: 0.5rem 1rem;
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      color: $text-color-secondary;
    }

    .inline-field-gender > div,
    .um-toggle,
    .field {
      > :nth-child(1) {
        color: $text-color-secondary;
      }
    }

    .field {
      .input,
      input {
        box-shadow: none;
        background-color: $white;
        border-color: $white;
        border-radius: 0;
        border: none !important;
        border-bottom: $panel-border !important;
        padding: 0;
        font-size: $font-size-md;
        color: $text-color-primary;
        font-weight: 400;

        .DateInput_input {
          border-bottom: none !important;
        }

        &.age {
          height: 32px;
          width: 40px;

          svg {
            margin-left: 5px;
          }
        }

        &:focus-visible {
          outline: none;
          border-bottom: 1px solid $focus-outline !important;
        }
      }

      .textarea {
        color: $text-color-primary;
        // both min-height and height is need to override the bulma sass styles
        min-height: 6rem;
        height: 6rem;
        // make max textarea height 100% and using overflow of the panel-body with max-height of 50vh,
        max-height: 100%;
        overflow: hidden;
      }

      .textarea-small {
        // both min-height and height is need to override the bulma sass styles
        min-height: 2rem;
        height: 2rem;
      }
    }

    .inline-field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .switch-field {
      display: flex;

      > * {
        flex: 1;
      }

      .switch-wrapper {
        text-align: right;
      }
    }

    .react-phone-container {
      margin-top: 10px;
      .flag-dropdown {
        height: 40px;
      }

      .react-phone-input {
        width: 100%;
        font-size: $font-size-md;
        padding-left: 48px;

        &:focus,
        &:active,
        &:hover {
          padding-left: 48px;
        }
      }
    }

    .react-phone-container .react-phone-input {
      height: 40px;
    }

    ::placeholder {
      color: $grey;
    }
  }

  .panel-placeholder {
    margin: 0;
    color: $grey;
  }

  &.hide-header {
    margin-top: 0;
    border: none;

    .panel-header {
      display: none;
    }

    .panel-body {
      padding: 0 0.25rem;
    }
  }

  &.nested-panel {
    width: 100%;
    margin-top: 0;
    border: none;
  }

  &.skeleton {
    .panel-header {
      display: block;
      padding-top: 10px;
    }
  }
}
