@use 'src/styles/global' as *;

#waitlist-list,
#queue-list,
#reservations-list {
  @include mobile() {
    margin-bottom: 6rem;
  }
}

#waitlist-list,
#queue-list--active,
#queue-list--removed,
#reservations-list {
  &.has-filter {
    margin-top: 30px;
  }

  .reservation-slot {
    background: $white;
    margin-bottom: 0.5rem;

    &-wrapper:last-of-type .reservation-slot-item {
      border-bottom-left-radius: $border-radius-md;
      border-bottom-right-radius: $border-radius-md;
    }

    &,
    &-header {
      border-top-left-radius: $border-radius-md;
      border-top-right-radius: $border-radius-md;
    }

    & {
      border-bottom-left-radius: $border-radius-md;
      border-bottom-right-radius: $border-radius-md;
    }

    &-header {
      background: $grey-primary;
      border-bottom: $list-item-border;
      height: 40px;
      color: $text-color-primary;
      font-weight: 400;
      font-size: $font-size-lg;
      position: sticky;
      top: 0;
      z-index: 1;

      .reservation-extra-info {
        font-size: $font-size-sm;
        color: $text-color-secondary;
      }
    }

    &-item {
      min-height: 80px;
      background: $white;
      border-bottom: $list-item-border;
      box-shadow: $list-item-box-shadow;
      font-weight: 400;
      color: $text-color-secondary;

      .name {
        color: $text-color-primary;
      }

      &.reservation-warning {
        border-left: 4px solid $attention-warning;
      }

      &.reservation-drawer-open {
        filter: brightness(0.9);
        background: $selected-list-item;
        box-shadow: none;
      }

      &.reservation-attention {
        background: $reservation-attention-background;
        color: $white;
        span {
          color: $white;
        }

        .name {
          color: $white;
          font-weight: 500;
        }

        .source {
          color: $white;
          border-color: $white;

          &.instagram {
            border: 1px solid $white;
            &:before {
              content: none;
            }
          }
        }

        .is-blacklisted {
          color: $white !important;
        }
      }

      .tables {
        font-size: $font-size-md;
        font-weight: 500;
      }

      .status-name {
        padding-top: 3px;
        font-size: $font-size-sm;
      }

      .reservation-charge-icon {
        font-size: $font-size-md;
      }
    }

    &-header,
    &-item {
      display: flex;
      align-items: center;

      > * {
        display: flex;

        &.reservation-extra-info {
          align-items: center;
        }

        &.reservation-basic-info > * {
          display: flex;
          align-items: center;
          padding: 0.5rem;

          svg,
          img {
            margin-right: 0.25rem;
          }

          .tags-wrapper {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .tag-bullet {
              max-width: 145px;
              @include mobile() {
                max-width: 90px;
              }
            }
          }

          &:nth-child(1) {
            flex: 1;
          }

          &:nth-child(2) {
            min-width: 50px;
            justify-content: center;
            padding: 0;
            font-size: $font-size-lg;

            @include desktopAndAbove {
              min-width: 70px;
            }

            @include tabletAndAbove() {
              padding: 0;
            }
          }

          &:nth-child(3) {
            width: 125px;
            padding-left: 0;
            justify-content: center;

            button.popover-toggle {
              position: relative;
              width: 100%;
              min-height: 62px;
              padding: 0 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              border-radius: $border-radius-md;
              background: #e55aaf;
              box-shadow: 0px 2px 2px #00000029;
              color: $white;
              cursor: pointer;
              &.reservation-attention-status {
                background: $attention-warning-status !important;
              }

              * {
                pointer-events: none;
              }

              svg {
                margin-right: 5px;
                .a {
                  fill: $white;
                }
              }

              span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .tables {
                  flex-direction: row;
                  align-items: center;
                }
              }
            }
          }
        }

        &.reservation-extra-info > * {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          flex: 1 1 0;
          width: 0;
          word-break: break-word;

          svg,
          img {
            margin-right: 0.25rem;
          }
        }
      }

      .reservation-basic-info {
        flex-basis: 40%;
        justify-content: space-between;
        max-width: 450px;

        @include mobile() {
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      .reservation-extra-info {
        flex: 1;
      }

      .source-wrapper {
        max-width: 125px;
      }
    }

    &-header {
      .source-wrapper {
        justify-content: center;
      }
    }

    &-item {
      .reservation-basic-info > * {
        word-break: break-word;
        word-wrap: normal;
        hyphens: auto;
        font-size: $font-size-sm;

        &:nth-child(1) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-right: 0;

          .guest-name {
            text-transform: capitalize;

            .table-status {
              svg,
              img {
                transform: translateY(0);
                margin-left: 0;
              }
            }
          }

          > :nth-child(1) {
            font-size: $font-size-lg;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: calc(100vw - 200px);
            @include tabletAndAbove {
              max-width: 190px; // 125 (status button) + 70 (guest count) - 5 padding
            }
            @include largeDesktop {
              max-width: 230px; // 125 (status button) + 70 (guest count) - 5 padding
            }
            @include betweenTabletAndDesktop {
              max-width: 75px;
            }
          }
        }
      }

      .reservation-extra-info {
        > * {
          position: relative;
          font-size: $font-size-md;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            height: 40px;
            width: 1px;
            background: $grey-primary;
          }
        }

        .inline-tags {
          display: block;
        }

        .rsv-notes {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          > * {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .waitlist-item-expiration--danger {
    color: $attention-warning;
  }

  .green-dot {
    position: relative;
    &::before {
      @include dot($yellow-green, 8px, 8px);
      position: absolute;
      left: 10px;
      right: 0;
    }

    .reservation-basic-info {
      &:first-child {
        padding-left: 15px;
      }
    }
  }
}

.reservations-header-loader {
  background: $grey-primary;
}
