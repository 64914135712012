/*Create ripple effec*/

.ripple {
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale3d(10, 10, 10);
  opacity: 0;
  transition:
    transform 0.5s,
    opacity 1s;
}

.ripple:active:after {
  transform: scale3d(0, 0, 0);
  opacity: 0.3;
  transition: 0s;
}
