$sidebar-width: 65px;
$mobile-funnel-header-height: 55px;
$mobile-funnel-footer-height: 120px;
$mobile-funnel-footer-height--small: 60px;
$mobile-max-width-on-tablet: 50rem;

$tablet-header-height: 60px;
$tablet-footer-height: 60px;
$tablet-height-with-quick-filter: 100px;

$border-radius: 0.125rem; // (2)
$border-radius-xs: 0.25rem; // (4)
$border-radius-sm: 0.375rem; // (6)
$border-radius-md: 0.5rem; // (8)
$border-radius-lg: 0.75rem; // (12)
$border-radius-xl: 1rem; // (16)

$list-item-border: 1px solid #1d1d1d2b;
$list-item-box-shadow: 1px 2px 2px #1d1d1d2b;
$panel-border: 2px solid #f4f4f4;

$block-box-shadow: 1px 2px 4px 1px #0000002f;

$header-box-shadow: 2px 2px 4px #0000002f;

$minor-list-item-box-shadow: 0.5px 0.5px 1px #1d1d1d2b;
$tag-height: 25px;

$funnel-sidebar-width: 360px;
$funnel-table-height: 65px;
$funnel-footer-height: 90px;

$details-tabs-height: 43px;
$details-tabs-border-bottom: 2px;
$details-view-height: calc(100% - #{$details-tabs-height + $details-tabs-border-bottom} - 8px);

$details-mobile-funnel-header-height: 105px;
$details-header-height: 52px;
$time-slot-height: 60px;
$time-slot-height-mobile: 70px;
$mobile-funnel-header-with-quick-filter: 150px;

$vh100: calc(var(--webrms-vh, 1vh) * 100);

$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$font-size-xs: 0.625rem; // (10)
$font-size-sm: 0.75rem; // (12)
$font-size-md: 0.875rem; // (14)
$font-size-lg: 1rem; // (16)
$font-size-xl: 1.125rem; // (18)
$font-size-xxl: 1.25rem; // (20)
$font-size-xxxl: 1.5rem; // (24)
