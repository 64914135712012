.webrmsCloseButtonOverride {
  .ReactModal__Overlay {
    .ReactModal__Content {
      .close-popup {
        position: absolute !important;
        z-index: 12 !important; // to override z-index of error-boundary
      }

      .go-back {
        position: absolute !important;
        z-index: 12 !important;
      }
    }
  }
}
