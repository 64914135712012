@use 'src/styles/global/colors' as *;
@use 'src/styles/global/variables' as *;

.supplement-panel {
  .subTotal,
  .fee,
  .product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid $grey-light;
    line-height: 1.25rem;

    &:last-child {
      border: 0;
    }
  }

  .refund {
    border-bottom: 1px solid $grey-light;
    color: $attention-warning;
    font-weight: 500;
  }

  .subTotal {
    font-weight: 500;
  }

  .products {
    border-bottom: 1px solid $grey-light;
    line-height: 1.25rem;

    &:last-of-type {
      border: 0;
    }

    > ul {
      @media print {
        padding-left: 0 !important;
      }
    }
  }

  .product {
    flex-direction: column;
  }

  .productTitle {
    display: block;
    font-weight: 500;
    line-height: 1.3125rem;
  }

  .productMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .productAddon {
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: $font-size-md;
    font-weight: 400;
    line-height: 1.25rem;
    color: $text-color-secondary;
  }
}
