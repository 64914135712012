@use 'src/styles/global' as *;

.copyIcon {
  @include button-reset;
  flex: unset;
  display: inline;
  margin: 0 !important;
  margin-left: pxToRem(5) !important;
  vertical-align: middle;
  cursor: pointer;
}
