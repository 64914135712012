@use 'src/styles/global/utils' as *;
@use 'src/styles/global/colors' as *;

.guest-name {
  text-transform: capitalize;
  color: $text-color-primary;

  > svg {
    vertical-align: middle;
    margin-right: pxToRem(4);
  }

  &.is-blacklisted {
    color: $danger-button !important;

    // This will contain the blacklist icon svg
    .blacklist-icon {
      position: relative;
      margin-right: pxToRem(4);
      display: inline-block;
      width: 19px;
      height: 19px;
      vertical-align: middle;
    }
  }

  &.is-white {
    color: $white !important;
  }

  &.exact-match {
    color: $green-primary !important;
    font-weight: 700;
  }

  .blacklist-tooltip {
    font-size: pxToRem(14);
    line-height: pxToRem(19);
    width: 450px;
    padding: pxToRem(10);
    text-transform: none;
  }
}
