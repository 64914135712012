@use '../global/utils' as *;

.main-app-wrapper {
  @include is-venue-in-warning {
    #main-content .appParent {
      #um-header-old-settings,
      .um-venue-settings-header {
        top: var(--payment-warning-height);
      }

      #sidebar {
        padding-top: var(--payment-warning-height);
      }
    }
  }
}
