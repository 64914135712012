@use 'src/styles/global/colors' as *;
@use 'src/styles/global/variables' as *;
@use 'src/styles/global/utils' as *;

#webrms-sidebar {
  --button-height: 55px;

  background: $color-primary;
  color: $white;
  z-index: 7;
  padding: 0;
  font-size: $font-size-xs;
  height: 100%;
  display: flex;
  flex-direction: row;

  @include is-venue-access-blocked {
    z-index: var(--blocked-access-z-index);
  }

  .overflow-sidebar {
    position: absolute;
    width: 100%;
    overflow: auto;
    height: calc(100% - var(--button-height) * 3); // subtracting the height of fixed sidebar
  }

  .fixed-sidebar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @keyframes icon-active {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(0.85);
    }
    60% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      display: flex;
      height: var(--button-height);
      cursor: pointer;

      &:hover {
        background: lighten($grey-dark, 5%);
      }

      .sidebar-item {
        position: relative;
        background: $color-primary;
        color: $white;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        word-break: break-word;
        white-space: nowrap;
        overflow: hidden;
        font-size: $font-size-xs;
        border: none;
        border-radius: 0;

        &:focus-visible {
          outline: 1px solid $focus-outline;
        }

        &.active {
          background: $grey-dark;
        }

        span {
          margin-top: 0.125rem;
        }

        .premium-icon {
          top: 0;
          right: pxToRem(3);
          position: absolute;

          span {
            margin-top: 0;
          }
        }

        // NOTE: make sure the name of class for beamer is same as umaiBeamerClassSelector.
        &.umai-beamer {
          .beamer_icon.active {
            top: 5px;
            right: 5px;
          }
        }

        &:focus,
        &:hover {
          background: lighten($grey-dark, 5%);
          color: inherit;
        }

        &:active {
          background: $grey-dark;
          color: inherit;

          svg {
            animation: icon-active 0.3s;
          }
        }
      }
    }

    .beta-info {
      position: absolute;
      top: 0;
      right: 3px;
      margin: 0;
    }
  }

  .sidebar-venue {
    display: none;
  }

  &.is-expanded {
    --button-height: 50px;

    z-index: 10;

    li,
    li > .sidebar-item {
      font-size: $font-size-md;
    }

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .sidebar-venue {
      display: flex;
      height: var(--button-height);
    }

    .sidebar-item,
    .outlet-wrapper {
      span {
        display: block;
      }
    }

    li {
      .sidebar-item,
      .outlet-wrapper {
        padding: 0 1rem;
        flex-direction: row;
        justify-content: flex-start;

        &:focus {
          outline: 1px solid $focus-outline;
        }

        svg,
        .outlet {
          width: 30px; // keeping the fix width keep keep margin align with the icon
          margin-right: 1rem;
          vertical-align: middle;
        }
      }
    }
  }

  .count-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 3px;
    background: $attention-warning;
    color: $white;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum', 'tnum';
    min-width: 22px;
    height: 22px;
    padding: 0 3px;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    border-radius: $border-radius-xl;
    z-index: 1;
    transition: all 0.1s ease-out;
  }

  .beamer_icon.active {
    background: $attention-warning;
  }
}
