@use 'src/styles/global' as *;

.guest-view-wrapper {
  padding: pxToRem(15) pxToRem(10);
  background: $white;
  overflow-x: hidden;
  overflow-y: auto;
  height: $details-view-height;

  .blacklist-label {
    display: flex;
    align-items: center;
    gap: pxToRem(5);

    &.is-blacklisted {
      color: $danger-button;
    }
  }

  .loyalty__membership {
    padding-top: pxToRem(8);
    margin-top: pxToRem(8);
    border-top: 2px solid $grey-primary;

    &-label {
      color: $text-color-secondary;
    }
  }
}
