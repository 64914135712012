@use '../global' as *;

.guest-basic-info-component {
  display: flex;
  position: relative;
  margin-bottom: 10px;

  .guest-avatar-and-tier-wrapper {
    position: relative;
    width: 100%;
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;

    &.is-blacklisted {
      border: 4px solid $danger-button;
    }

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .guest-avatar-placeholder {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    @include tabletAndAbove() {
      max-width: pxToRem(155);
      max-height: pxToRem(155);
    }
  }

  .guest-details {
    padding: 0 0 0 1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    color: $text-color-secondary;
    font-size: $font-size-md;

    .guest-name {
      text-transform: capitalize;
      font-size: $font-size-lg;
      color: $text-color-primary;
    }

    .guest-job-title {
      font-size: $font-size-lg;
      color: $text-color-primary;
    }

    .guest-email,
    .guest-phone-number {
      a {
        color: $text-color-secondary;
        &:hover {
          text-decoration: underline;
        }
      }
      &.dark-phone {
        a {
          color: $text-color-primary;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    // Required to get tooltip on hover working (.change-guest-button-wrapper has z-index of 1 and is overlapping)
    .blacklist-icon {
      z-index: 2;
    }
  }

  .guest-photo-edit {
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    left: 0;
    right: 0;
    bottom: 10px;

    span:first-child {
      filter: brightness(1.2);
      background: rgba(0, 0, 0, 0.1);
      margin: 0;
      padding: 0.5rem 1.5rem;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .temporary-guest-info__value {
    font-weight: 400 !important;
  }

  &.skeleton {
    display: flex;
    align-items: center;

    .skeleton-bar {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }
}
