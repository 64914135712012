@use '../global/variables' as *;
@use '../global/utils' as *;
@use '../global/breakpoints' as *;

.main-app-wrapper {
  #main-content .umaiGiftcardDashboardParent {
    .giftcard-layout {
      height: calc(#{$vh100} - #{$tablet-footer-height});

      @include is-venue-in-warning {
        height: calc(#{$vh100} - var(--payment-warning-height) - #{$tablet-footer-height});
      }

      @include mobile {
        padding-top: pxToRem(150);
      }
    }

    @include is-venue-in-warning {
      .giftcard-header {
        top: var(--payment-warning-height);
      }
    }

    @include tabletAndAbove() {
      .giftcard-header {
        left: $sidebar-width;
      }
    }

    .no-giftcards {
      height: calc(#{$vh100} - #{$tablet-footer-height});

      @include is-venue-in-warning {
        height: calc(#{$vh100} - var(--payment-warning-height) - #{$tablet-footer-height});
      }

      @include mobile {
        height: calc(#{$vh100} - #{$mobile-funnel-footer-height--small});

        @include is-venue-in-warning {
          height: calc(
            #{$vh100} - var(--payment-warning-height) - #{$mobile-funnel-footer-height--small}
          );
        }
      }
    }
  }
}
