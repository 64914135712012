@use 'src/styles/global' as *;

.header {
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: pxToRem(8);
  padding: pxToRem(8) pxToRem(16);
  border-radius: $border-radius-md $border-radius-md 0 0;
  background-color: $notify;
  &.isMissed {
    background-color: $danger-button;
  }

  @include mobile {
    gap: pxToRem(4);
    padding: pxToRem(4) pxToRem(12);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: pxToRem(8);

    @include mobile {
      gap: pxToRem(4);
    }

    &:hover {
      svg {
        animation: jiggle 0.4s;
      }
    }

    @keyframes jiggle {
      0% {
        transform: rotate3d(0, 0, 1, -7deg);
      }
      50% {
        transform: rotate3d(0, 0, 1, 7deg);
      }
      100% {
        transform: rotate3d(0, 0, 1, -7deg);
      }
    }

    .text {
      display: inline-block;
      color: $white;
      font-size: $font-size-xl;
      font-weight: 700;
      line-height: pxToRem(21.78);

      .time {
        font-size: $font-size-sm;
        font-weight: normal;
        line-height: pxToRem(16.94);
        margin-left: pxToRem(4);
      }
    }

    .today {
      font-size: $font-size-md;
      font-weight: 700;
      line-height: pxToRem(16.94);
      color: $yellow-green;
      padding: pxToRem(4);
      border-radius: $border-radius-xs;
      background-color: rgba($yellow-green, 0.2);
      &.isMissed {
        background-color: rgba($yellow-green, 0.4);
      }
    }
  }

  .action {
    @include button-reset;
    width: 17px;
    height: 17px;
    &:first-of-type {
      margin-right: pxToRem(16);
    }

    svg {
      vertical-align: middle;
    }
  }
}
