@use 'src/styles/global/colors' as *;

.webrms-loader {
  height: 60px;
  width: 60px;
  position: relative;
  display: inline-block;
  border: 6px solid $grey-light;
  border-top: 6px solid $green-primary;
  border-radius: 50%;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: spin 2s linear infinite;
  cursor: wait;
  z-index: 100000;

  @keyframes spin {
    0% {
      transform: rotate3d(0, 0, 1, 1deg);
    }
    50% {
      transform: rotate3d(0, 0, 1, 180deg);
    }
    100% {
      transform: rotate3d(0, 0, 1, 360deg);
    }
  }
}

.horizontal-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
  cursor: wait;

  &.small {
    height: 10px;
  }
}

.horizontal-loader div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $green-primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.horizontal-loader {
  &.small div {
    width: 8px;
    height: 8px;
  }
}

.horizontal-loader div:nth-child(1) {
  left: 8px;
  animation: horizontal-loader1 0.6s infinite;
}
.horizontal-loader div:nth-child(2) {
  left: 8px;
  animation: horizontal-loader2 0.6s infinite;
}
.horizontal-loader div:nth-child(3) {
  left: 32px;
  animation: horizontal-loader2 0.6s infinite;
}
.horizontal-loader div:nth-child(4) {
  left: 56px;
  animation: horizontal-loader3 0.6s infinite;
}
@keyframes horizontal-loader1 {
  0% {
    transform: scale3d(0, 0, 0);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes horizontal-loader3 {
  0% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(0, 0, 0);
  }
}
@keyframes horizontal-loader2 {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(24px, 0, 0);
  }
}

.centered-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: wait;
}
