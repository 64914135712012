$black: #000000;
$white: #ffffff;
$grey: #c7c7cd;
$grey-very-light: #eceef0;
$grey-light: #e2e2e2;
$grey-dark: #3b4252;
$grey-background: #d8e1e8;
$grey-primary: #f4f4f4;
$yellow-green: #8fcf3b;
$blue: #1f8fe1;
$blue-dark: #2381c0;
$blue-light: #7297ee;
$blue-cyan: #17a2b8;
$red: red;
$violet: #ac43bf;
$forest-green: #1c973e;
$yellow-orange: #e5ab39;
$vivid-orange: #fc7a41;
$green-primary: #19a69a;
$green-light: #bce4e0;
$color-primary: #06031a;
$text-color-secondary: #5d6572;
$text-color-primary: #000000;
$cream: #ceb37d;
$golden: #efa76d;
$golden-yellow: #ffd503;
$attention-warning: #eb6464;
$attention-warning-status: #c22d2d;
$danger-button: #e65359;
$danger-button-hover: #eb6464;
$success-button: #19a69a;
$success-button-hover: #2e8080;
$disabled-button: #d9d9d9;
$disabled-button-hover: #00000029;
$footer-button: #1e1e36;
$gray-header: #4b5463;
$border-bottom: #d9d9d9;
$focus-outline: $blue;
$light-orange: #ffa318;
$color-facebook: #1877f2;
$color-google-blue: #4f5bd5;
$light_blue: #859198;
$instagram-gradient: linear-gradient(
  79.5deg,
  #feda75 -1.12%,
  #fa7e1e 24.68%,
  #d62976 49.11%,
  #962fbf 71.51%,
  #4f5bd5 96.63%
);
$primary: #19a69a;
$dark: #1b1b30;
$selected-list-item: #eef1f4;

// RESERVATION STATUSES
$empty: #55616a;
$reserved: #2980b9;
$seated: #e55aaf;
$cancelled: #686868;
$no-show: #404040;
$arrived: #c072b2;
$starter: #3f7b84;
$main: #81abcc;
$dessert: #5c76aa;
$cleared: #e5ab39;
$bill-dropped: #db7d23;
$paid: #719f44;
$finish-reservation: #47c871;
$confirmed: #46b4b5;
$left-message: #fc7a41;
$sent-message: #fc8f02;
$late: #844dd6;
$partially-seated: #ac4098;

$notify: #3f3b73;
$cancelled-notify: #686868;
$expired-notify: #8b89ab;
$new-notify: #9592b1;
$available-notify: #747474;

$remove-queue: #686868;
$queue: #2980b9;

$reservation-attention-background: repeating-linear-gradient(
  290deg,
  $attention-warning,
  $attention-warning 6px,
  #e65359 6px,
  #e65359 8px
);

$blocked-tables-background: repeating-linear-gradient(
  290deg,
  #1e1e36,
  #1e1e36 4px,
  $color-primary 4px,
  $color-primary 8px
);
