@use 'src/styles/global' as *;

.access-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $white;
  color: $text-color-primary;
  box-shadow: $list-item-box-shadow;
  padding: 1.5rem;
  border-radius: $border-radius-xl;
  position: absolute;
  gap: 1.5rem;
  width: 600px;
  max-height: 70vh;
  overflow-y: auto;
  top: 20%;
  left: calc(calc(100% - 600px) / 2);
  right: 0;
  @include mobile() {
    gap: 0.75rem;
    width: 95%;
    top: 15%;
    left: 2.5%;
  }

  .umai-logo {
    height: 60px;
    width: 200px;
    vertical-align: text-bottom;
    margin-right: pxToRem(10);
    @include tabletAndAbove() {
      height: 80px;
    }
    path,
    rect {
      fill: $green-primary;
    }
  }

  .go-to-umai-button {
    @include button-reset;
    background-color: $green-primary;
    font-weight: 400;
    line-height: pxToRem(19);
    text-align: center;
    color: $white;
    padding: pxToRem(10);
    margin-top: pxToRem(10);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: $border-radius-xl;
    width: 340px;
    height: 60px;
    @include mobile {
      width: 100%;
    }

    &:focus {
      outline: 1px solid $focus-outline;
    }

    &:hover {
      background-color: darken($color: $green-primary, $amount: 5);
    }

    &:disabled {
      background-color: $disabled-button;
      cursor: default;
      outline: none;
    }
  }

  .header {
    font-weight: 300;
    letter-spacing: pxToRem(2);
    color: $green-primary;
    font-size: 3.5rem;
    @include mobile() {
      font-size: 2rem;
    }
  }

  .title {
    font-weight: 500;
    font-size: $font-size-xxl;
  }

  .description {
    font-size: $font-size-lg;
    padding: 0 1rem;
    @include mobile() {
      padding: 0;
    }
  }

  .sub-title {
    color: $green-primary;
    font-size: $font-size-lg;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: darken($green-primary, 5%);
    }
  }

  .footer {
    width: 100%;
    margin: 0;
  }
}
