@use '../global/variables' as *;
@use '../global/utils' as *;

.main-app-wrapper {
  #main-content {
    #calendar-root {
      margin-bottom: $tablet-footer-height + 10px;
    }
  }
}
