@use 'src/styles/global' as *;
@use '../../IncomingCall.module.scss' as *; // NOTE: import IncomingCall.module.scss is also needed for global styles to apply for layout

.incomingCallLoader {
  @extend .incomingCall;

  .filters {
    display: flex;
    flex-direction: row;
    gap: pxToRem(8);
    margin-bottom: pxToRem(24);
  }

  .filterButton {
    @include button-reset;
    padding: pxToRem(10) pxToRem(15);
    border-radius: 200px;
    border: 1px solid;
    line-height: pxToRem(15);

    @include mobile {
      padding: pxToRem(10);
    }
  }

  .callLogList {
    display: flex;
    flex-direction: column;
    gap: pxToRem(24);
    margin-bottom: pxToRem(24);
  }
}
