@use 'src/styles/global' as *;

.empty-state-wrapper {
  position: fixed;
  top: 30%;
  left: $sidebar-width;
  right: 0;
  min-height: 200px;
  @include is-venue-in-warning {
    top: calc(30% + var(--payment-warning-height));
  }

  &.waitlist,
  &.queue {
    top: 25%;
    @include is-venue-in-warning {
      top: calc(25% + var(--payment-warning-height));
    }
  }

  @include mobile() {
    padding: 0 0.5rem;
    top: 25%;
    left: 0;
    right: 0;
    @include is-venue-in-warning {
      top: calc(25% + var(--payment-warning-height));
    }
  }

  &.relative-positioned {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    height: auto;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg,
  img {
    margin-bottom: 1rem;
    transition: 0.65s cubic-bezier(0.25, 0.1, 0, 2.05);

    &:hover {
      transform: scale(1.1) rotate(-2deg);
    }
  }

  .title {
    margin-bottom: 0.5rem;
    font-size: $font-size-xxxl;
  }

  .description {
    font-size: $font-size-lg;
    color: $text-color-secondary;
  }

  @include mobile() {
    img {
      margin-bottom: 0;
    }
    .title {
      margin-bottom: 0;
      font-size: $font-size-xxl;
    }
  }
}
