@use '../global/variables' as *;
@use '../global/utils' as *;

.main-app-wrapper {
  @include is-venue-in-warning {
    #main-content .umaiSettingsParent {
      .settings-container #um-header,
      .settings-container #um-sidebar {
        top: var(--payment-warning-height);
      }

      .hamburger-menu {
        top: calc(20px + var(--payment-warning-height));
      }
    }
  }

  #main-content .umaiSettingsParent {
    overflow: auto;
    .settings-container #um-header,
    .settings-container #um-sidebar {
      left: $sidebar-width;
      bottom: $tablet-footer-height;
    }

    &.mobile-device {
      .settings-container #um-header,
      .settings-container #um-sidebar {
        left: 0;
      }
    }
  }
}
