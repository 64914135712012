@use '../global' as *;

.footer-menu-wrapper {
  max-height: $mobile-funnel-footer-height;
  background: $color-primary;
  display: flex;
  flex-direction: column;
  z-index: 3;

  .footer-menu {
    flex: 1;
    display: flex;

    ul {
      flex: 1;
      display: flex;
      justify-content: space-around;

      // Allow user to scroll menu in case it doesn't fit on the screen
      @include mobile() {
        overflow-x: auto;
      }

      li:has(:first-child) {
        padding: 0.65rem 0.5rem;

        @include mobile() {
          padding: 0.4rem pxToRem(2);
        }
      }

      li,
      li > a,
      li > button,
      li > div,
      li > article {
        position: relative;
        flex: 1;
        display: flex;
        justify-content: center;
        color: $white;
        font-size: $font-size-sm;
      }

      li > a,
      li > button,
      li > div,
      li > article {
        color: $white;
        border-color: transparent;
        align-items: center;
      }

      li > a,
      li > button,
      li.venue-selector-wrapper > div {
        flex-direction: column;
        cursor: pointer;
      }

      li > button {
        flex: none;
      }

      .footer-button {
        min-width: 60px;
        height: 44px;
        background-color: $footer-button;
        color: $white;
        border: 1px solid rgba($gray-header, 0.3);
        border-radius: $border-radius-md;
        font-size: $font-size-sm;
        &:hover {
          background: lighten($footer-button, 5%);
        }

        button {
          @include button-reset;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-right: 0.3rem;
          }
        }

        &.custom-availability-selected {
          border: 1px solid $focus-outline;
        }

        &__availability-summary {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .availability-summary__wrapper {
          display: flex;
          align-items: center;
          gap: 2px;
        }

        .availability-summary__details {
          display: flex;
          flex-direction: column;
        }

        .availability-summary__count {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1px;
        }
      }

      .no-button {
        padding: 0 10px;
        background: $footer-button;
        &:hover {
          background: $footer-button;
        }
      }
    }

    .beta-info {
      position: absolute;
      top: 0;
      right: 3px;
      margin: 0;
    }
  }

  #main-footer-menu {
    li a,
    li button {
      span {
        margin-top: 0.25rem;
      }

      .premium-icon {
        position: absolute;
        top: pxToRem(-4);
        right: pxToRem(-6);

        span {
          margin-top: 0;
        }
      }
    }
  }

  .beamer_icon.active {
    background: $attention-warning;
  }
}
