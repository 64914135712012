@use 'src/styles/global' as *;

.tags-wrapper {
  display: flex;
  word-break: keep-all;
  flex-wrap: wrap;

  &.is-clickable {
    cursor: pointer;
  }

  > * {
    display: block;
    max-width: 100%;
  }

  &.inline {
    display: inline;
    > * {
      display: inline;
    }
    text-align: left;
  }

  .tag-bullet-count {
    display: inline-flex;
    align-items: center;
    .count {
      padding: 0 2px 0 4px;
    }
  }

  .tag-bullet {
    line-height: $tag-height;
    border-radius: $border-radius-sm;
    padding: 0 0.25rem;
    background: $violet;
    border: 1px solid $violet;
    color: $white;
    display: inline-block;
    margin-top: 0.125rem;
    margin-right: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    font-size: $font-size-sm;
    text-overflow: ellipsis;
    max-width: 100%;
    text-align: center;
    filter: brightness(1.085);

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.auto-tag-first-visit {
      background: $forest-green;
      border: 1px solid $forest-green;
    }

    &.auto-tags {
      background: $yellow-orange;
      border: 1px solid $yellow-orange;
    }

    &.last-tag {
      opacity: 0.6;
      font-size: $font-size-lg;
      font-weight: bold;
      text-align: center;
    }
  }

  &.guest {
    .tag-bullet {
      background: $blue-dark;
      border: 1px solid $blue-dark;
    }

    .auto-tags {
      background: $yellow-orange;
      border: 1px solid $yellow-orange;
    }

    .vip-tag {
      line-height: calc(#{$tag-height} - 2px);
      background: $white;
      border: 2px solid $golden-yellow;
      // important is used to override when attention reservation is changing the color to white
      color: darken($golden-yellow, 3%) !important;
      font-weight: 600;
      // reset brightness to make it more visible
      filter: brightness(1);
      svg {
        // important is used to override for mobile tags
        margin-right: 2px !important;
        vertical-align: text-bottom;
      }
    }

    svg {
      vertical-align: middle;
    }
  }

  &.reservation {
    margin-right: 0.25rem;
  }

  &.automated-tags {
    .tag-bullet {
      background: $yellow-orange;
      border: 1px solid $yellow-orange;
    }

    .auto-tag-first-visit {
      background: $forest-green;
      border: 1px solid $forest-green;
    }
  }
}
