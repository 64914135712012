@use '../global' as *;

.um-currency-divider {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $border-bottom;
  @include mobile {
    width: 80vw;
    margin: auto;
  }

  :first-child {
    margin: auto;
    vertical-align: middle;
    justify-content: center;
    flex: 0 0 45%;
    text-align: end;
    padding-right: 5px;
    font-size: $font-size-lg;
  }

  input,
  .input {
    text-align: start !important;
    margin-left: 5px;
    line-height: normal;
  }
}
.um-text-header {
  color: $text-color-primary;
  font-size: $font-size-xl;
  font-weight: 500;
}

.um-text-muted {
  color: $text-color-secondary;
  font-size: $font-size-md;
}

.um-modal-buttons-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: $font-size-lg;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    height: 60px;
    width: 355px;
    border: none;
    @include mobile() {
      max-width: 270px;
      height: 50px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  svg,
  img {
    max-width: 2rem;
    margin-right: 0.5rem;
  }
}
