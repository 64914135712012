@use 'src/styles/global' as *;
@use '../../IncomingCall.module.scss'; // import IncomingCall.module.scss are needed for global styles to apply for layout

.incomingCallSubscription {
  max-height: 90vh;
  top: auto;
  gap: pxToRem(16);
  padding: 1rem 2rem 0.5rem;
  flex-flow: row wrap;

  @include mobile {
    max-height: 70vh;
  }

  .title {
    font-size: $font-size-xl;
    font-weight: 400;
    line-height: pxToRem(21.78);
    text-align: center;
  }

  .description {
    p {
      text-align: left;
      color: $text-color-secondary;
      font-size: $font-size-md;

      span {
        display: inline-block;
        margin-bottom: pxToRem(16);
      }
    }

    img {
      margin: auto;
      border-radius: 1rem;
      object-fit: contain;
      max-height: 400px;
    }
  }

  .subTitle {
    font-size: $font-size-lg;
    font-weight: 400;
    line-height: pxToRem(19.36);
    text-align: center;
  }
}

.unSubsIncomingCall {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../../../assets/images/incoming-call-freemium.png') no-repeat top left;

  @include mobileAndLandscape {
    background:
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../../../assets/images/incoming-call-freemium.png') no-repeat top left;
  }

  @include mobile {
    background:
      linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('../../../../assets/images/incoming-call-freemium-mobile.png') no-repeat top center;
  }

  & {
    background-size: contain;
  }
}
