@use 'global' as *;
@use 'plugins/ripple' as *;

#public-layout-wrapper {
  background:
    linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../assets/images/login_background.jpg') no-repeat center center fixed;
  background-size: cover;

  #public-layout-body {
    padding: 2.75rem 0 0 0;
    justify-content: center;
    align-items: flex-start;
  }

  #logo-wrapper {
    text-align: center;
    margin-bottom: 5.5rem;
    svg {
      height: 65px;
      width: 234px;
      @include tabletAndAbove() {
        height: 85px;
      }
    }
  }

  .back-arrow {
    @include button-reset;
    position: absolute;
    left: 16px;
    top: 41px;
    @include tabletAndAbove() {
      left: 38px;
    }
  }

  #form-wrapper {
    width: 100%;
    padding: 1.5rem;
    background: rgba(0, 0, 0, 0.5);
    color: $white;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    font-size: $font-size-lg;
    line-height: 19px;
    border-radius: 32px;

    @include tabletAndAbove() {
      max-width: 388px;
    }

    .login-error {
      text-align: left;
    }

    h1 {
      font-weight: 700;
      font-size: $font-size-xxxl;
      line-height: 29px;
      margin-bottom: 1rem;
    }

    p {
      font-weight: 400;
      font-size: $font-size-lg;
      line-height: 19px;
      margin-bottom: 2.5rem;
    }

    .help {
      font-size: $font-size-lg;
      margin-top: 0.5rem;
      font-weight: 400;
      line-height: 19px;
    }

    .field {
      position: relative;
      .login-input-icon {
        position: absolute;
        left: 20px;
        margin-top: 1.25rem;
      }

      .show-hide-password {
        @include button-reset;
        position: absolute;
        right: 20px;
        top: 80px;
      }

      .input {
        background: $white;
        width: 100%;
        height: 60px;
        padding-left: 3.5rem;
        font-weight: 400;
        font-size: $font-size-lg;
        line-height: 19px;

        &__warning-border {
          outline: none !important;
          border: 2px solid $danger-button;
          box-shadow: 0 0 2px $danger-button;
        }

        &:first-of-type {
          border-radius: 16px 16px 0px 0px;
        }

        &:last-of-type {
          border-radius: 0px 0px 16px 16px;
        }

        &:first-of-type:last-of-type {
          border-radius: 16px 16px 16px 16px;
        }
      }
    }

    #button-link-wrapper {
      height: 17px;
      margin-top: 1rem;
      text-align: right;
      font-weight: 400;
      font-size: $font-size-md;
      line-height: 17px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 5px;

      a,
      a:hover,
      a:focus,
      a:active {
        display: block;
        background: transparent;
        border: none;
        color: $white;
        text-transform: capitalize;
        margin: 1rem 0rem;
        cursor: pointer;
        border-bottom: 1px solid $white;
      }

      a:hover,
      a:focus,
      a:active {
        color: $white;
      }
    }

    button[type='submit'] {
      @extend .ripple;
      font-size: $font-size-lg;
      font-weight: 400;
      line-height: 19px;
      margin-top: 1rem;
      width: 100%;
      height: 60px;
      box-shadow: $list-item-box-shadow;
      border-radius: 16px;
      &:focus {
        // used specially to highlight the submit button when user navigate with keyboard(Tab)
        box-shadow: 0 0 2px 2px $blue-cyan;
      }

      &:disabled {
        background-color: $grey;
        cursor: not-allowed;
        opacity: 1;
      }
    }
  }
}
