@use 'src/styles/global' as *;

.main-app-wrapper {
  $um-sidebar-top: 58px;

  @include is-venue-in-warning {
    #main-content .umaiFeedbackParent {
      #um-feedback #um-sidebar {
        top: calc(#{$um-sidebar-top} + var(--payment-warning-height));
      }
    }
  }

  #main-content .umaiFeedbackParent {
    #um-feedback #um-sidebar {
      left: $sidebar-width;
      bottom: $tablet-footer-height;
      top: $um-sidebar-top;
    }
    #um-feedback #um-header {
      left: $sidebar-width;
      bottom: $tablet-footer-height;
      top: 0;
    }

    #um-feedback .rc-drawer-content-wrapper {
      @include tabletAndAbove() {
        height: calc(100% - $tablet-footer-height);
      }
    }

    &.mobile-device {
      #um-feedback #um-sidebar,
      #um-feedback #um-header {
        left: 0;
      }
    }

    #um-feedback {
      .btn-filter-toggle img {
        max-width: 12px;
      }

      .uB-dropdown-menu .DayPicker {
        .DayPickerNavigation_button {
          height: auto;
          padding: 4px 9px;
        }
        .DayPickerKeyboardShortcuts_show__bottomRight {
          display: none;
        }

        .CalendarDay {
          text-align: center;
          font-size: $font-size-md;
          vertical-align: middle;
        }

        .CalendarMonth_caption {
          strong {
            color: $green-primary;
          }
        }
      }
    }
  }
}
