@use '../global/variables' as *;
@use '../global/colors' as *;

.uB-btn-danger {
  background-color: $danger-button;
  border: 1px solid $danger-button;
  border-radius: $border-radius-xl;
  color: $white;
  font-size: $font-size-lg;
  &:hover,
  &:focus {
    background-color: $danger-button-hover;
    border: 1px solid $danger-button-hover;
  }
}

.uB-btn-success {
  background-color: $success-button;
  border: 1px solid $success-button;
  border-radius: $border-radius-xl;
  color: $white;
  font-size: $font-size-lg;
  &:hover,
  &:focus {
    background-color: $success-button-hover;
    border: 1px solid $success-button-hover;
  }
}

.uB-btn-disabled {
  background-color: $disabled-button;
  border: 1px solid $disabled-button;
  border-radius: $border-radius-xl;
  color: $white;
  font-size: $font-size-lg;
  &:hover,
  &:focus {
    background-color: $disabled-button-hover;
    border: 1px solid $disabled-button-hover;
  }
}

.uB-btn-outline-dark {
  border-radius: $border-radius-xl;
  border: 1px solid $grey-light;
  background-color: transparent;
  color: $text-color-primary;
  box-shadow: none;
  font-weight: 400;
  font-size: $font-size-lg;

  &:hover,
  &:focus {
    background-color: $grey-very-light;
  }
}
