@use '../global/variables' as *;
@use '../global/colors' as *;

#tablet-and-desktop-thirdparty-layout-wrapper,
#tablet-and-desktop-layout-wrapper {
  position: relative;
  height: $vh100;
  background-color: $grey-background;

  #webrms-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $sidebar-width;
    color: $white;
    transition: width 0.3s;

    &::before {
      content: '';
      background-color: rgba($color-primary, 0.25);
      transition: background-color 5s ease-in-out;
    }

    nav {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color-primary;
    }

    &.is-expanded {
      width: $sidebar-width + 300px;
    }
  }
}
