@use 'global' as *;

html {
  overflow: auto;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;

  button,
  a {
    cursor: pointer;
    transition: all 200ms ease-out;
    &:disabled {
      cursor: not-allowed;
    }
  }

  .button {
    font-size: $font-size-sm;
    @include largeDesktop {
      font-size: $font-size-md;
    }
    @include mobile() {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#webrms-root {
  height: 100%;
  width: 100%;
  background-color: $grey-background;
}
