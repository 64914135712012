@use 'src/styles/global' as *;

#master-guests-list {
  min-height: calc(100% - 60px);

  .guest-items {
    background: $white;
    margin: 0.5rem 0;
    cursor: pointer;
    overflow: hidden;

    &-wrapper:last-of-type .reservation-slot-item {
      border-bottom-left-radius: $border-radius-md;
      border-bottom-right-radius: $border-radius-md;
    }

    &,
    &-header {
      border-top-left-radius: $border-radius-md;
      border-top-right-radius: $border-radius-md;
    }

    & {
      border-bottom-left-radius: $border-radius-md;
      border-bottom-right-radius: $border-radius-md;
    }

    &-header {
      background: $grey-primary;
      border-bottom: $list-item-border;
      height: 40px;
      color: $black;
      font-weight: 400;
      font-size: $font-size-lg;
      position: sticky;
      top: 0;
      z-index: 2;
      letter-spacing: 0.05em;

      .guest-extra-info,
      .guest-visit-info {
        font-size: $font-size-sm;
        color: $text-color-secondary;
      }
    }

    &-item {
      min-height: 5rem;
      background: $white;
      border-bottom: $list-item-border;
      box-shadow: $list-item-box-shadow;
      font-weight: 400;

      &.guest-detail-drawer-open {
        filter: brightness(0.9);
        background: $selected-list-item;
        box-shadow: none;
      }

      &.exact-match {
        border-left: 3px solid $green-primary;
      }
    }

    &-header,
    &-item {
      display: flex;
      align-items: center;

      > * {
        display: flex;

        &.guest-extra-info {
          align-items: center;
        }

        &.guest-basic-info > * {
          padding: 5px;

          .guest-basic-info-component {
            flex: 1;
            margin: 0;

            .guest-avatar-and-tier-wrapper {
              max-width: 60px;
              max-height: 60px;

              &.is-blacklisted {
                border: 3px solid $danger-button;
              }
            }

            .guest-details {
              overflow: hidden;
              font-size: $font-size-md;
              color: $text-color-secondary;
            }

            .guest-email,
            .guest-phone-number {
              font-size: $font-size-md;
              color: $text-color-secondary;

              &.exact-match {
                font-weight: 700;
                color: $green-primary;
              }
            }

            .guest-name {
              display: inline-block;
              font-size: $font-size-lg;
              color: $text-color-primary;
              margin: 0;
            }
          }

          .tags-wrapper {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .tag-bullet {
              max-width: 9rem;
              @include mobile() {
                max-width: 5.625rem;
              }
            }
          }

          &:nth-child(1) {
            flex: 1;
          }
        }

        &.guest-extra-info > * {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          flex: 1 1 0;
          width: 0;
          word-break: break-word;
        }

        &.guest-visit-info > * {
          padding: 0.5rem 0.875rem;
        }

        .visit-info-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          width: 80%;
          font-size: $font-size-sm;
        }

        .visit-info-detail {
          font-weight: 400;
          font-size: $font-size-md;
        }
      }

      .guest-basic-info {
        display: flex;
        flex-basis: 40%;
        justify-content: space-between;
        max-width: 21.875rem;

        @include mobile() {
          flex-basis: 100%;
          max-width: 100%;

          .guest-basic-details {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .guest-extra-info {
        flex: 1;
        flex-basis: 60%;
        max-width: 37.5rem;
      }
      .guest-visit-info {
        flex-basis: 60%;
        justify-content: space-between;

        > * {
          flex: 1;
        }
      }
    }

    &-item {
      .guest-extra-info,
      .guest-visit-info {
        > * {
          position: relative;
          height: 5rem;
          font-size: $font-size-md;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            height: 40px;
            width: 1px;
            background: $grey-primary;
          }
        }

        .rsv-notes {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          > * {
            margin-bottom: 0.5rem;
          }
        }
      }

      .guest-details {
        > * {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 30ch;

          @include mobile() {
            width: 25ch;
          }
        }
      }
    }
  }
}

.detail-view-wrapper {
  background: $grey-background;
  height: 100%;
}

.guest-detail-drawer {
  .drawer-right.drawer-open .drawer-content-wrapper {
    box-shadow: none;
  }
  .drawer-right .drawer-content-wrapper {
    // transparent is used to show for the payment warnings on top of details header
    background: transparent;
    border: 0;
    height: 100%;
    @include tabletAndAbove() {
      height: calc(100% - #{$tablet-footer-height});
      min-width: 38.75rem;
    }
  }

  @include is-venue-in-warning {
    .drawer-content-wrapper {
      padding-top: var(--payment-warning-height);
    }
  }

  .guest-list-view-wrapper {
    height: 100%;
    @include tabletAndAbove() {
      margin: 0 10px;
    }
  }

  .drawer {
    z-index: 5;
  }

  .drawer-content {
    overflow: hidden;
  }
}

.loader-wrapper {
  position: absolute;
  left: 50%;
  bottom: 80px;
}

#guest-details-header {
  background: $white;
  height: $details-header-height;
  padding: 0.5rem;
  display: flex;
  box-shadow: $header-box-shadow;
  margin-bottom: calc(#{$tablet-header-height} - #{$details-header-height});
  @include mobile() {
    margin-bottom: calc(#{$mobile-funnel-header-height} - #{$details-header-height});
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: $details-header-height;
    width: 2px;
    background: $grey-primary;
  }

  .back-button-wrapper {
    border: none;
    box-shadow: none;
    margin-left: 5px;
  }

  button {
    height: 34px !important;
    border-radius: $border-radius-md;
    border: 1px solid #686868;
  }

  .left-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .right-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .add-reservation {
      color: $green-primary;
      background: $white;
      border: 1px solid $green-primary;
      width: 140px;

      &:hover {
        color: $white;
        background: $green-primary;
      }

      &:disabled {
        border: none;
        background: $disabled-button;
        color: $white;
        cursor: not-allowed;
      }
    }
  }
}
