@use 'src/styles/global' as *;

.guest-venue-and-partner-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  @include mobile {
    flex-flow: column wrap;
  }

  .guest-transactions-summary-wrapper {
    width: 100%;
    max-width: 900px;
  }

  &.in-two-rows {
    .guest-transactions-summary-wrapper {
      width: 50%;
      max-width: 450px;
    }
  }
}

.guest-transactions-summary-header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 0.5rem;
  font-size: $font-size-md;
  font-weight: 500;
  line-height: 17px;
}

.guest-transactions-summary {
  display: flex;
  flex-wrap: wrap;

  &.in-two-rows {
    > * {
      flex-basis: 33%;
    }
  }

  &.skeleton-summary {
    &.in-two-rows {
      > * {
        flex-basis: 29%;
      }
    }

    > * {
      display: block;
      padding: 5px 0;
    }
  }

  &.show-border {
    border-left: 1px solid $border-bottom;
    padding-left: 5px;
  }

  > * {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding: 0.5rem 0;

    > :first-child {
      cursor: pointer;
      color: $text-color-secondary;
      font-size: $font-size-sm;
    }

    > :last-child {
      color: $text-color-primary;
      font-weight: 400;
      font-size: $font-size-lg;
    }
  }

  svg {
    vertical-align: text-top;
    margin-left: 3px;
  }
}

.guest-transactions-popover-overlay {
  .ant-popover-content {
    background: white !important;
    .ant-popover-arrow {
      border-top-color: white !important;
      border-left-color: white !important;
    }
  }

  .guest-transactions-popover-wrapper {
    max-width: 400px;
    @include mobile {
      max-width: 320px;
    }
    @include smallMobile {
      max-width: 270px;
    }

    padding: 1rem;
    border-radius: $border-radius-md;
    font-size: $font-size-lg;
    color: $text-color-primary;
    background-color: white;
    z-index: 3;
  }
}
