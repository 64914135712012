@mixin button-reset() {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  background-color: transparent;
  box-shadow: none;
  border-radius: initial;
  transform: inherit;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin hide-text() {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@function stripUnit($value) {
  @return calc($value / calc($value * 0 + 1));
}

@function pxToRem($pxValue) {
  @return #{calc(stripUnit($pxValue) / stripUnit(16))}rem;
}

@mixin dot($color, $width, $height) {
  content: '';
  width: $height;
  height: $width;
  border-radius: 100%;
  background: $color;
  filter: brightness(1.15);
}

@mixin is-venue-in-warning() {
  body.venue-in-warning & {
    @content;
  }
}

@mixin is-venue-access-blocked() {
  body.venue-access-blocked & {
    @content;
  }
}
