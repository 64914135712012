@use 'src/styles/global' as *;

.queue-funnel-popup {
  &.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
    border-radius: $border-radius-xl !important;
    overflow: hidden !important;

    .close-popup {
      right: 1.25rem;
    }
  }
}

.queue-funnel-wrapper {
  max-width: 944px;
  max-height: 80vh;
  padding: 30px 30px 0;
  overflow: auto;

  &.unsubscribed-wrapper {
    position: relative;
    height: 500px;
    max-width: 600px;
  }

  @include tabletAndAbove() {
    width: 80vw;
    height: 90vh;
  }

  .party-sizes-wrapper {
    max-width: 370px;
    margin: 1rem auto;

    > * {
      height: 42px;
      width: 15%;
      @include tabletAndAbove() {
        margin: 0 2.5% 15px;
        width: 10.75%;
      }
    }
  }

  .access-message {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }

  .select-guest-wrapper {
    width: 100%;
    margin: 2rem auto 0;

    .search-guests-instructions {
      margin-top: 3rem;
    }
  }

  .create-new-guest-wrapper {
    max-width: 37.5rem;
    margin: 0px auto 4rem;
  }
  .funnel-selection-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 15px 0;
    text-align: center;
    color: $text-color-primary;
    font-size: $font-size-lg;
    line-height: pxToRem(19);
    font-weight: 500;

    svg,
    img {
      margin-right: 0.5rem;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .hidden-header {
    .funnel-selection-title {
      display: none;
    }
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    .add-button {
      width: 340px;
      height: 60px;
      box-shadow: 0px 2px 2px #00000029;
      border-radius: $border-radius-xl;
      color: $white;
      background-color: $disabled-button !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-lg;

      svg,
      img {
        margin-right: 0.5rem;
        display: inline-block;
        vertical-align: middle;
      }

      &.is-active {
        background-color: $primary !important;
      }
    }
  }

  .back-icon {
    @include button-reset;
    position: fixed;
    left: 1.25rem;
    top: 1.25rem;
  }
}
