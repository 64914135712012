@use '../global' as *;

.source-wrapper {
  display: flex;
  flex-direction: column;
  .source {
    margin-top: 0.5rem;
  }
}

.source {
  min-width: 75px;
  min-height: 25px;
  border-radius: $border-radius-md;
  padding: 0 0.65rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: $font-size-sm;

  &.in-house {
    border: 1px solid $color-primary;
    color: $text-color-primary;
  }

  &.umai {
    border: 1px solid $green-primary;
    color: $green-primary;
  }

  &.widget {
    border: 1px solid $yellow-orange;
    color: $yellow-orange;
  }

  &.featured {
    border: 1px solid $vivid-orange;
    color: $vivid-orange;
  }

  &.walk-in {
    border: 1px solid $blue-light;
    color: $blue-light;
  }

  &.tier {
    border: 1px solid $golden;
    color: $golden;
    height: 20px;
  }

  &.facebook {
    border: 1px solid $color-facebook;
    color: $color-facebook;
  }

  &.instagram {
    position: relative;
    background: $instagram-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    &:before {
      position: absolute;
      content: '';
      inset: 0;
      border-radius: $border-radius-md;
      padding: 1px;
      background: $instagram-gradient;
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  &.google {
    border: 1px solid $color-google-blue;
    color: $color-google-blue;
  }

  &.import {
    border: 1px solid $cancelled;
    color: $cancelled;
  }
}
