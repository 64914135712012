@use 'src/styles/global' as *;

#reservations-header {
  display: flex;
  flex-flow: column nowrap;
  &.is-tablet-nav {
    flex-flow: row nowrap;
  }
  background: $grey-primary;
  color: $text-color-secondary;

  .header__nav {
    position: relative;
    padding: 0;
    border-bottom: $list-item-border;
    font-size: $font-size-md;

    &.is-tablet-nav {
      border-bottom: none;
      flex-basis: 33%;
      flex-grow: 0;
      max-width: 450px;
      button {
        @include button-reset;
        height: 50px;
        display: flex;
        align-items: center;
      }
    }

    .header__nav-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;

      .header__nav-item {
        display: flex;
        align-items: center;

        &:first-of-type {
          button {
            &.is-active {
              box-shadow: 4px 0px 6px 0px #00000029;
            }
          }
        }

        &:last-of-type {
          button {
            &.is-active {
              box-shadow: -4px 0px 6px 0px #00000029;
            }
          }
        }
      }

      button {
        @include button-reset;
        position: relative;
        flex: 1 1;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-color-secondary;
        padding: 0 5px;
        margin: 1px;
        transition:
          color 0.15s ease-in-out,
          background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;

        &:focus-visible {
          outline: 1px solid $focus-outline;
        }

        &:active {
          top: 0;
        }

        &.is-active {
          font-weight: 500;
          background: $white;
          color: $text-color-primary;
          box-shadow:
            3px 0px 6px 0px #00000029,
            -3px 0px 6px 0px #00000029;
        }
      }

      * {
        flex: 1;
      }
    }
  }

  .search-and-filters-wrapper {
    margin-bottom: 0;
    padding: 0.5rem;
    &.is-tablet-nav {
      padding: 0 0.5rem;
    }

    .search-and-filters-list {
      display: grid;
      grid-template-areas: 'search-input quick-filter header-button1 header-button2 header-button3 header-button4';
      grid-template-columns: 1fr repeat(5, auto);
      align-content: center;
      height: 100%;
    }

    li {
      margin: 0 0.25rem;
      position: relative;

      &.search-input {
        grid-area: search-input;
        padding-right: 0.5rem;
      }

      .green-dot {
        position: relative;
        &::before {
          @include dot($yellow-green, 7px, 7px);
          margin-right: 0.5rem;
        }

        &::after {
          display: none;
        }
      }

      .refresh-icon {
        svg {
          transition: transform 0.3s ease-in-out;
          &:hover {
            transform: rotate(180deg);
          }
        }
      }

      &.header-button {
        &.button1 {
          grid-area: header-button1;
        }

        &.button2 {
          grid-area: header-button2;
          position: relative;

          .orange-dot {
            &::before {
              @include dot($light-orange, 7px, 7px);
              position: absolute;
              top: 0;
              right: 2px;
            }
          }
        }

        &.button3 {
          grid-area: header-button3;
        }

        &.button4 {
          grid-area: header-button4;
          svg {
            width: 16px;
          }
        }

        button {
          min-width: 30px;
          max-width: 80px;
          max-height: 36px;
          background: $white;
          box-shadow: 0px 1px 1px #0000002f;
          border-radius: $border-radius-md;
          font-size: $font-size-sm;
          color: $text-color-primary;

          &:focus-visible {
            outline: 1px solid $focus-outline;
          }

          svg {
            margin-right: 3px;
            @include mobile {
              margin-right: 0;
            }
            vertical-align: middle;
          }
        }
      }
    }

    .quick-filter {
      position: absolute;
      right: 0;
      left: 0;
      top: 100px;
      height: 45px;
      background: $grey-primary;
      margin: 0;
      display: flex;
      align-items: center;

      &.is-tablet-filter {
        top: 50px;
      }

      &.is-desktop-filter {
        grid-area: quick-filter;
        position: relative;
        top: 0;
        height: 100%;
        margin: 0;
      }

      .quick-filter-list {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        padding: 0;

        .filter-btn {
          text-transform: capitalize;
          min-width: 90px;
          height: 34px !important;
          border-radius: $border-radius-xl;
          border: 1px solid #686868;
          padding: 0.5rem;
          box-shadow: none;
          transition:
            color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;

          &:focus-visible,
          &:hover {
            // using the box-shadow to set currentColor through inline styles
            // not using border because it causes a layout shift when applied with 2px
            box-shadow: inset 0 0 0 1px currentColor;
          }
        }
      }
      &.is-tablet-filter {
        .quick-filter-list {
          justify-content: center;
          .filter-btn {
            margin: 0 8px;
          }
        }
      }

      &.is-desktop-filter {
        .quick-filter-list {
          .filter-btn {
            min-width: 70px;
            margin: 0;
          }
        }
      }
    }
  }

  .waitlist-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: $green-primary;
    font-size: $font-size-md;
    line-height: 20px;
    color: $white;
  }
}
