@use '../global' as *;

#tablet-and-desktop-layout-wrapper #main-content.timeline-layout,
#mobile-layout-wrapper.timeline-layout {
  background: $footer-button;
  padding-top: 0;
  .body {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $footer-button;
    margin: 0;
    border-radius: 0;
    @include mobile {
      min-height: $vh100;
      padding-bottom: $mobile-funnel-footer-height--small;
    }
    @include mobileAndLandscape {
      min-height: $vh100;
      padding-bottom: $mobile-funnel-footer-height--small;
    }
  }
}

.timeline-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background: transparentize($black, 0.7);
  filter: brightness(1.1);
  cursor: wait;
}
