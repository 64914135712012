@use '../global/variables' as *;
@use '../global/colors' as *;

.outlet-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  * {
    pointer-events: none;
  }

  span {
    display: none;
  }

  // NOTE: make sure the name of class for beamer is same as umaiBeamerClassSelector.
  &.umai-beamer {
    .beamer_icon.active {
      top: -5px;
      right: -5px;
    }
  }

  .outlet {
    background: $green-primary;
    border: 2px solid $white;
    width: 30px;
    min-width: 30px; // needed to make a outlet circle round, with was override by margin of venue-selector on mobile
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $white;
    font-size: $font-size-md !important;
  }
}
