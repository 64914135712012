@use '../global/variables' as *;
@use '../global/utils' as *;

.main-app-wrapper {
  @include is-venue-in-warning {
    #main-content .umaiShopDashBoardParent {
      .shop-header {
        top: var(--payment-warning-height);
      }

      .order-detail-container {
        margin-top: var(--payment-warning-height);
      }

      #orders-list {
        min-height: calc(100vh - calc(120px + var(--payment-warning-height)));
      }
    }
  }

  #main-content .umaiShopDashBoardParent {
    overflow: visible;
    .shop-header,
    #shop-footer {
      left: $sidebar-width;
    }
  }
}
