@use 'src/styles/global/utils' as *;

.whatsAppButton {
  @include button-reset;
  margin-left: pxToRem(6);

  svg {
    vertical-align: middle;
  }
}
