@use 'src/styles/global' as *;

.filters {
  display: flex;
  flex-direction: row;
  gap: pxToRem(8);
  margin-bottom: pxToRem(24);
}

.filterButton {
  @include button-reset;
  padding: pxToRem(10) pxToRem(15);
  border-radius: 200px;
  border: 1px solid transparent;
  font-size: $font-size-sm;
  line-height: pxToRem(15);

  @include mobile {
    padding: pxToRem(10);
  }

  &.filterMissedCall {
    border-color: $danger-button;
    color: $danger-button;

    &:hover {
      background: rgba($danger-button, 0.2);
    }

    &.isActive {
      background: $danger-button;
      color: $white;
    }
  }

  &.filterIncomingCalls {
    border-color: $cancelled;
    color: $cancelled;

    &:hover {
      background: rgba($cancelled, 0.2);
    }

    &.isActive {
      background: $cancelled;
      color: $white;
    }
  }

  &.filterIsUpcoming {
    border-color: $reserved;
    color: $reserved;

    &:hover {
      background: rgba($reserved, 0.2);
    }

    &.isActive {
      background: $reserved;
      color: $white;
    }
  }
}
