@use 'src/styles/global' as *;

.alertsContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  font-size: $font-size-lg;

  @include tabletAndAbove {
    width: calc(100% - #{$sidebar-width});
    left: $sidebar-width;
  }

  @include mobileAndLandscape {
    width: 100%;
    left: 0;
  }

  &.fullWidth {
    width: 100%;
    left: 0;
  }

  height: auto; // must be auto so that we don't have to manage the height on different screen sizes
  text-align: left;
  font-weight: 400;
  color: $white;
  z-index: 8; // must be greater then or equal of reservation-detail-drawer z-index to work
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  strong {
    color: $white;
  }

  .alert {
    position: relative;
    padding: 10px;
    background-color: $danger-button;

    &.referral {
      background-color: $green-primary;
    }

    &:not(:last-child) {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    }
  }

  .alertMessages {
    list-style: none;

    .firstMessage {
      display: list-item;
      margin-right: 25px;
      @include mobile {
        margin-right: 15px;
      }
      @include desktopAndAbove {
        margin-right: 150px; // 25px for close + 100px for paynow button + 10px of marin
      }
    }
  }

  .closeIcon {
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    @include mobile {
      top: 10px;
    }
  }

  .actionButtonContainer {
    position: relative;
    top: 5px;
    display: flex;
    gap: 0.5rem;

    @include desktopAndAbove {
      position: absolute;
      top: 10px;
      right: 35px;
    }

    &.referralButtonContainer {
      @include desktopAndAbove {
        right: 10px;
      }
    }
  }

  .actionButtonNoThanks {
    @include button-reset;
    background-color: transparent;
    font-size: $font-size-md;
    color: $white;
    height: 25px;
    border-radius: $border-radius-md;
    padding: 0 pxToRem(8);

    &:hover {
      background-color: darken($color: $green-primary, $amount: 10);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    }
  }

  .actionButton {
    @include button-reset;
    background-color: $white;
    color: $text-color-primary;
    border-radius: $border-radius-md;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    font-size: $font-size-md;
    padding: 0 pxToRem(8);
    height: 25px;
    text-align: center;

    &:focus {
      outline: 1px solid $focus-outline;
    }

    &:hover {
      background-color: darken($color: $white, $amount: 10);
    }

    &:disabled {
      background-color: $grey;
      cursor: default;
      outline: none;
    }
  }
}
