@use 'src/styles/global' as *;

.footer {
  height: 48px;
  border-top: 1px solid $light_blue;
  border-radius: 0 0 $border-radius-md $border-radius-md;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .action {
    @include button-reset;
    flex: 1;
    height: 100%;
    min-width: 130px;
    padding: pxToRem(5);
    font-size: $font-size-sm;
    font-weight: 400;
    line-height: pxToRem(14.52);
    border-right: 1px solid $light_blue;

    @include mobile {
      min-width: unset;
    }

    &.active {
      color: $white;
      background-color: $footer-button;
      &:hover {
        background-color: lighten($footer-button, 7%);
        box-shadow: inset 0 0 0 1px $footer-button;
      }

      &:disabled {
        background-color: lighten($footer-button, 15%);
        color: $text-color-primary;
        box-shadow: none;
      }
    }

    &.seated {
      color: $seated;
      &:disabled {
        color: $seated;
      }
    }

    &:first-of-type {
      border-bottom-left-radius: $border-radius-md;
    }

    &:last-of-type {
      border-bottom-right-radius: $border-radius-md;
      border-right: none;
    }

    &:hover {
      background-color: $grey-light;
      box-shadow: inset 0 0 0 1px white;
    }

    &:disabled {
      background-color: $disabled-button;
      color: $text-color-primary;
      box-shadow: none;
    }

    .label {
      display: inline-block;
      padding-top: pxToRem(3);
    }

    svg {
      vertical-align: middle;
      margin-right: pxToRem(4);
    }
  }
}
