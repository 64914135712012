@use 'src/styles/global' as *;

.callPopupTrigger {
  @include button-reset;
  position: fixed;
  width: 38px;
  height: 38px;
  top: pxToRem(8);
  right: 0;
  border-radius: $border-radius-md 0 0 $border-radius-md;
  box-shadow: 0px 3px 6px 0px #00000029;
  background-color: $notify;
  color: $white;
  z-index: 99999; // using same as antd

  svg {
    vertical-align: middle;
  }

  &:hover {
    svg {
      transform: scale(1.15);
    }
  }

  &.hideTrigger {
    visibility: hidden;
    transform: translateX(38px);
  }
}

:global(.callPopupRoot) {
  max-width: 435px !important;
  @include mobile {
    width: 100%;
  }
}

.callPopup {
  box-shadow: 0px 3px 6px 0px #00000029;
  border-radius: $border-radius-md;
  background-color: $white;

  @include mobile {
    box-shadow: 0px 2px 16px 0px #00000080;
  }
}
