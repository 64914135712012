@use 'src/styles/global' as *;

#details-header {
  background: $white;
  height: $details-header-height;
  display: flex;
  padding: 5px 10px;
  box-shadow: $header-box-shadow;
  margin-bottom: calc(#{$tablet-header-height} - #{$details-header-height});
  @include mobile() {
    margin-bottom: calc(#{$mobile-funnel-header-height} - #{$details-header-height});
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: $details-header-height;
    width: 2px;
    background: $grey-primary;
  }

  .back-button-wrapper {
    margin-right: 8px;
    font-size: $font-size-md;
    border: none;
    box-shadow: none;
    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  button {
    height: 34px !important;
    border-radius: $border-radius-md;
    border: 1px solid #686868;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &:focus-visible,
    &:hover {
      // using the box-shadow to set currentColor through inline styles
      // not using border because it causes a layout shift when applied with 2px
      box-shadow: inset 0 0 0 1px currentColor;
    }
  }

  button.prev-status {
    color: $blue-dark;
    border: 1px solid $blue-dark;
    svg {
      margin-right: 0.25rem;
    }
  }

  .next-status {
    text-transform: capitalize;
    min-width: 90px;
  }

  .space-between-button {
    margin-right: 5px;
  }

  .left-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .right-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .add-reservation-button {
    border: 0;
    background: $success-button;
    color: $white;

    &:hover {
      background: $success-button-hover;
      // need to override for button hover box-shadow
      box-shadow: none;
    }
  }

  .seat-queue-button {
    width: 54px;
    color: $white;
    background: $seated;
    border: 1px solid $seated;
    margin-right: pxToRem(5);
  }

  .add-to-queue-button {
    color: $queue;
    border: 1px solid $queue;
    font-size: $font-size-md;

    svg {
      margin-right: pxToRem(5);
    }
  }

  .remove-from-queue {
    color: $remove-queue;
    border: 1px solid $remove-queue;
  }
}

.details-tabs-wrapper {
  margin: 0 0.5rem;
  @include mobile {
    margin: 0;
  }
  height: 100%;
  &.is-guest-present {
    height: $details-view-height;
  }
}

.details-tabs {
  display: flex;
  border-bottom: #{$details-tabs-border-bottom} solid $grey-background;

  li {
    font-size: $font-size-lg;
    flex: 0 1 50%;
    @include mobile {
      font-size: $font-size-md;
      flex: 0 1 100%;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-top-left-radius: $border-radius-md;
    border-top-right-radius: $border-radius-md;
    transition: all 0.5s ease-in-out;

    &:first-of-type {
      button {
        box-shadow: 4px 0px 6px 0px #00000029;
        border-top-left-radius: $border-radius-md;
      }
    }

    &:last-of-type {
      button {
        box-shadow: -4px 0px 6px 0px #00000029;
        border-top-right-radius: $border-radius-md;
      }
    }

    button {
      @include button-reset;
      color: $text-color-primary;
      flex: 1;
      height: $details-tabs-height;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: $grey-primary;

      &.is-active {
        font-weight: 500;
        background: $white;
        z-index: 1;
      }
    }
  }
}

.details-view-wrapper {
  background: $grey-background;
  height: 100%;
}

.reservation-detail-drawer {
  .drawer-right.drawer-open .drawer-content-wrapper {
    box-shadow: none;
  }
  .drawer-right .drawer-content-wrapper {
    // transparent is used to show for the payment warnings on top of details header
    background: transparent;
    border: 0;
    height: 100%;
    @include tabletAndAbove() {
      height: calc(100% - #{$tablet-footer-height});
      min-width: 620px;
    }
  }

  @include is-venue-in-warning {
    .drawer-content-wrapper {
      padding-top: var(--payment-warning-height);
    }
  }

  .drawer {
    // z-index greater then or equal of sidebar to hide sidebar behind the backdrop of drawer
    // NOTE: temporary fix, need to check why items in timeline are not closing drawer on unmount
    z-index: 7;
  }

  .drawer-content {
    overflow: hidden;
  }
}

.reservation-details-component {
  position: relative;
  padding: pxToRem(15) pxToRem(10);
  background: $white;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  height: $details-view-height;
  transition: all 0.3s ease-in-out;

  .reservation-details-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .button[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      touch-action: none;
    }

    > * {
      color: $text-color-secondary;
      margin-right: 1rem;
      border-radius: $border-radius-md;
      height: 62px;
      max-width: 160px;
      border: 1px solid #f4f4f430;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.25rem;
      box-shadow: $block-box-shadow;
      font-weight: 400;
      font-size: $font-size-md;
      text-align: center;

      @include mobile {
        min-width: 85px;
        margin-right: 0.5rem;
      }
      @include tabletAndAbove {
        min-width: 100px;
        max-width: 250px;
      }
      @include largeDesktop {
        min-width: 160px;
        max-width: 450px;
      }

      &.finished-reservation {
        box-shadow: none;
        background: $grey-primary;
      }

      > :last-child {
        color: $text-color-primary;
        font-size: $font-size-lg;
        font-weight: 400;
      }

      &.disabled {
        cursor: not-allowed;
        box-shadow: none;
        border: 1px solid $border-bottom;
      }
    }
    .queue-time-button {
      box-shadow: none;
    }

    > :last-child {
      margin-right: 0;
    }
  }

  .attention-warning-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $attention-warning;
    text-align: center;
    justify-content: center;
    color: $white;
    font-size: $font-size-md;
    font-weight: 500;
    padding: 0.55rem;
    margin-top: 0.5rem;
    border-radius: $border-radius;

    :first-child {
      margin-right: 1rem;
    }

    a,
    button {
      height: 30px !important;
      @include mobile() {
        height: 34px !important;
      }
      width: 70px;
      background-color: $attention-warning;
      border: 1px solid $white;
      border-radius: $border-radius-md;
      color: $white;
      align-items: center;
    }
  }

  .deposit-reservation {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-lg;
    .reservation-charge-icon {
      padding: 1rem 0;
      width: 100%;
    }
    .payment_expiration {
      color: $paid;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include mobile() {
        padding-bottom: 0.25rem;
      }
    }
  }

  .created-wrapper {
    > :nth-child(1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      padding-bottom: 0.5rem;
      &:last-of-type {
        padding-bottom: 0;
      }

      > * {
        margin-top: -0.125rem;
      }
    }
  }
}
