@use '../global/variables' as *;
@use '../global/colors' as *;
@use '../global/utils' as *;

.main-app-wrapper {
  @include is-venue-in-warning {
    #main-content .umaiAnalyticsParent {
      #um-analytics {
        #ua-header {
          top: var(--payment-warning-height);
        }

        .ua-dropdown-wrapper.show {
          top: var(--payment-warning-height);
        }
      }
    }
  }

  #main-content .umaiAnalyticsParent {
    overflow: auto;
    .ua-modal-backdrop {
      background-color: rgba(0, 0, 0, 0.55);
    }

    #um-analytics {
      .ua-tab-links-wrapper li a {
        padding: 1rem;
      }

      #ua-header {
        left: $sidebar-width;
      }

      .um-dropdown .DayPicker {
        .DayPickerNavigation_button {
          height: auto;
          padding: 6px 9px;
        }

        .DayPickerKeyboardShortcuts_show__bottomRight {
          display: none;
        }

        .CalendarDay {
          text-align: center;
          vertical-align: middle;
        }

        .CalendarMonth_caption {
          strong {
            color: $green-primary;
          }
        }
      }
    }
  }
}
