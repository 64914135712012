@use 'src/styles/global' as *;

.searchInput {
  position: relative;
  width: 100%;

  input {
    border: none;
    box-shadow: none;
    padding-left: pxToRem(35);
    max-height: 36px;
    border-radius: $border-radius-md;
    font-size: $font-size-lg;
    color: $text-color-secondary;
    vertical-align: middle;

    &:focus-visible {
      outline: 1px solid $focus-outline;
    }

    &::placeholder {
      color: $disabled-button;
    }
  }

  // using important because it button class is overridden by using id attributes like #master-guests-header
  .clearSearch {
    @include button-reset;
    position: absolute !important;
    padding: pxToRem(3);
    right: pxToRem(5);
    top: 60%;
    transform: translateY(-50%) !important;
    box-shadow: none !important;
    height: auto !important;
    width: auto !important;
    min-width: auto !important;
    border: 1px solid transparent;

    &:active,
    &:hover {
      transition: none;
      border: 1px inset $black;
    }
  }

  .searchIcon {
    position: absolute;
    top: 60%;
    left: pxToRem(10);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
  }
}
