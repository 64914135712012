@use 'src/styles/global' as *;

// Fixes for tablet layout
:global(#tablet-and-desktop-layout-wrapper > #main-content.incoming-call) {
  padding-top: 0;
  background: $white;
}
:global(#tablet-and-desktop-layout-wrapper > #main-content.incoming-call .body) {
  margin: 0;
}

// Fixes for mobile layout
:global(#mobile-layout-wrapper.incoming-call) {
  padding-top: 0;
  background: $white;
  height: 100%;
}
:global(#mobile-layout-wrapper.incoming-call .body) {
  margin: 0;
  padding: 0;
  height: calc(#{$vh100} - #{$mobile-funnel-footer-height--small});
}

.incomingCall {
  width: 100%;
  min-height: 100%;
  background: $white;
  padding: pxToRem(40) pxToRem(24);

  @include mobile {
    padding: pxToRem(16) pxToRem(8);
  }

  .header {
    margin-bottom: pxToRem(40);
    @include mobile {
      margin-bottom: pxToRem(20);
    }

    .title {
      font-size: $font-size-xl;
      font-weight: 700;
      line-height: pxToRem(21.78);
      color: $text-color-primary;
      padding-bottom: pxToRem(8);
    }

    .subtitle {
      font-size: $font-size-lg;
      font-weight: 400;
      line-height: pxToRem(19.36);
      color: $text-color-primary;
    }
  }
}
