@use 'src/styles/global' as *;

#mobile-layout-wrapper {
  position: relative;
  overflow: hidden;
  background-color: $grey-background;
  padding-top: $mobile-funnel-header-height;
  &.is-details {
    padding-top: $details-mobile-funnel-header-height;
  }
  &.has-quick-filter {
    padding-top: $mobile-funnel-header-with-quick-filter;

    .body {
      &.is-details {
        height: calc(#{$vh100} - #{$mobile-funnel-header-with-quick-filter});
      }
    }
  }

  @include is-venue-in-warning {
    > header {
      top: var(--payment-warning-height);
    }
    .body {
      padding-top: var(--payment-warning-height);
    }
  }

  > header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
  }

  .body {
    padding-bottom: $mobile-funnel-footer-height;
    overflow: auto;
    height: calc(#{$vh100} - #{$mobile-funnel-header-height});
    margin: 5px;
    scroll-behavior: smooth;

    &.is-details {
      height: calc(#{$vh100} - #{$details-mobile-funnel-header-height});
    }

    &.guest-list-layout {
      padding-bottom: 70px;
    }

    &.is-white {
      background: $white;
    }

    &.is-funnel {
      overflow: initial;
      padding-bottom: 90px;
      height: auto;
      min-height: calc(#{$vh100} - #{$mobile-funnel-header-height});
      margin: 0;

      &.funnel-timestep {
        padding-bottom: 0;
      }
      &.is-confirmation-view {
        height: calc(#{$vh100} - #{$mobile-funnel-header-height});
        padding-bottom: 0;
        background-color: $white;
      }
    }
  }

  #mobile-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @include is-venue-access-blocked {
      z-index: var(--blocked-access-z-index);
    }

    #footer-filters {
      border-bottom: 1px solid $dark;
    }

    #main-footer-menu {
      > ul {
        padding: 0 pxToRem(2);
      }

      li {
        padding: 0.4rem pxToRem(2);
        flex: unset;
      }
    }
  }
}
