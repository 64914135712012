@use 'src/styles/global' as *;

#master-guests-header {
  background: $grey-primary;

  @include mobile() {
    display: flex;
  }

  nav {
    position: relative;
    padding: 0;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;

      li {
        display: flex;
        align-items: center;
      }
    }
  }

  button {
    min-width: 80px;
    max-width: 140px;
    max-height: 36px;
    box-shadow: 0px 1px 1px #0000002f;
    border-radius: $border-radius-md;
    background: $white;

    svg,
    img {
      max-width: 1rem;
    }
  }

  #guest-search-and-filter {
    margin-bottom: 0;

    @include tabletAndAbove() {
      padding: 0 0.5rem;
    }

    @include mobile() {
      padding: 0.75rem 0.5rem;
      flex: 2;
    }

    ul {
      height: 100%;
    }

    li {
      margin: 0 0.25rem;
      position: relative;

      @include mobile() {
        margin: 0;
      }

      &.search-input {
        padding-right: 0.5rem;
        width: 100%;
      }
    }
  }

  #create-new-guest-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5rem;

    @include mobile() {
      justify-content: center;
      padding: 0 0.5rem 0;
    }

    button {
      background: $primary;
      color: $white;
    }
  }
}
