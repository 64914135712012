@use 'src/styles/global' as *;

.error-boundary-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  z-index: 11;

  @include mobile() {
    top: 10%;
    right: 0;
  }

  .error-boundary-box {
    max-height: 85vh;
    width: 600px;
    @include mobile() {
      width: 95vw;
    }
    background: $white;
    border-radius: $border-radius-md;
    box-shadow: 3px 3px 8px 3px #0000002f;
    padding: 1rem;
    color: $text-color-primary;
    overflow: auto;

    // used for confetti on hover on reload of chunkload box
    &.no-overflow {
      overflow: unset;
    }

    .title {
      font-size: $font-size-xxxl;
      .emoji {
        font-size: 1.7rem;
      }
      svg {
        vertical-align: text-bottom;
      }
      font-weight: 600;
      margin-bottom: 1rem;
    }

    .description {
      margin-bottom: 0.5rem;
      font-weight: 400;
      font-size: $font-size-lg;
    }

    .reload-button {
      font-size: $font-size-lg;
      margin-top: 1.25rem;
      display: flex;
      text-align: center;
      justify-content: center;
      align-content: center;
      button {
        height: 44px;
        width: 200px;
      }
    }

    .link-button {
      @include button-reset;
      display: inline;
      color: $blue;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .bold {
      font-weight: 500;
    }

    .fit-content {
      width: fit-content;
    }

    .troubleshoot {
      font-size: $font-size-lg;

      .troubleshoot-title {
        font-size: $font-size-xxl;
        font-weight: 500;
        padding: 0.5rem 0;
      }

      .troubleshoot-description {
        margin-bottom: 1rem;
      }

      .troubleshoot-list {
        margin-top: 1rem;
        list-style: inside;
        li {
          margin-bottom: 0.75rem;
          text-align: start;
        }
      }
    }

    .error-help-links {
      padding-bottom: 0.5rem;
    }
  }
}
