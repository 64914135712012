@use 'src/styles/global' as *;

.goBackToHome {
  @include button-reset;
  background-color: $green-primary;
  font-weight: 400;
  font-size: $font-size-lg;
  line-height: 19px;
  text-align: center;
  vertical-align: middle;
  color: $white;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: $border-radius-xl;
  margin-top: 20px;
  width: 340px;
  height: 60px;
  @include mobile {
    width: 280px;
  }

  &:focus {
    outline: 1px solid $focus-outline;
  }

  &:hover {
    background-color: darken($color: $green-primary, $amount: 5);
  }

  svg {
    vertical-align: text-bottom;
    // reset margin from empty-state
    margin: 0 5px;
  }
}
