@use '../global/variables' as *;
@use '../global/utils' as *;
@use '../global/colors' as *;

#network-notification-backdrop,
.antd-popover-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.3;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  background: $black;
}

#network-notification-backdrop {
  z-index: 99999; // used same as antd popover
}

#umai-antd-popovers-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 100;

  @include is-venue-access-blocked {
    z-index: var(--blocked-access-z-index);
  }
}

.ant-popover {
  max-width: 90%;
  z-index: 99999;

  .ant-popover-content {
    background: $grey-primary;
    border-radius: $border-radius-md;
    box-shadow: $block-box-shadow;
    overflow: hidden;

    .ant-popover-inner {
      background: inherit;
      border-radius: inherit;
      padding: 0;
    }
  }

  &.ant-popover-placement-right,
  &.ant-popover-placement-rightTop,
  &.ant-popover-placement-rightBottom {
    padding-left: unset;
  }

  &.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
    border-bottom-color: $grey-primary;
    border-right-color: $grey-primary;
    right: 6px;
    border-width: 9.24264069px;
  }

  &.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    border-bottom-color: $grey-primary;
    border-left-color: $grey-primary;
    left: 6px;
    border-width: 9.24264069px;
  }

  &.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    border-top-color: $grey-primary;
    border-left-color: $grey-primary;
    border-width: 9.24264069px;
    top: 2px;
  }

  &.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
  &.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    border-right-color: $grey-primary;
    border-bottom-color: $grey-primary;
    border-width: 9.24264069px;
    bottom: 1px;
  }

  &.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    top: 20px;
  }
}

.ant-popover-inner-content {
  padding: 0;
  overflow: hidden;
  > * {
    overscroll-behavior-y: contain;
    outline: 0;
  }
}
